import { CreditLimitStatus, ProductLineStatus } from '../enums/status.enum';
import { GenericObject } from '@utils/models/Types';
import { BlanketStatus } from '@pages/distributor/enums/product-lines.enum';

export class StatusConstant {
  static readonly creditLimitStatusList = [
    CreditLimitStatus.ACTIVATED,
    CreditLimitStatus.DEACTIVATED,
    CreditLimitStatus.SUSPENDED
  ];
  static readonly creditLimitStatusLabelMapping: GenericObject = {
    [CreditLimitStatus.ACTIVATED]: 'STATUS.CREDIT_LIMIT.ACTIVATED',
    [CreditLimitStatus.DEACTIVATED]: 'STATUS.CREDIT_LIMIT.DEACTIVATED',
    [CreditLimitStatus.SUSPENDED]: 'STATUS.CREDIT_LIMIT.SUSPENDED'
  };

  static readonly productLineStatusList = [
    ProductLineStatus.ACTIVE,
    ProductLineStatus.PENDING,
    ProductLineStatus.CLOSED
  ];

  static readonly productLineStatusLabelMapping: GenericObject = {
    [ProductLineStatus.ACTIVE]: 'STATUS.PRODUCT_LINE_STATUS.ACTIVE',
    [ProductLineStatus.PENDING]: 'STATUS.PRODUCT_LINE_STATUS.PENDING',
    [ProductLineStatus.CLOSED]: 'STATUS.PRODUCT_LINE_STATUS.CLOSED',
    [ProductLineStatus.INCOMPLETE]: 'STATUS.PRODUCT_LINE_STATUS.INCOMPLETE'
  };

  static readonly financialBlanketsStatusLabelMapping = {
    [BlanketStatus.ACTIVE]: 'STATUS.BLANKETS.ACTIVE',
    [BlanketStatus.SUSPENDED]: 'STATUS.BLANKETS.SUSPENDED'
  };
}
