import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import {
  DistributorGroupSection,
  DistributorManagerProps,
  DistributorProps,
  DistributorSection,
  DistributorStatus
} from '../enums/distributor-manager.enum';
import { FundingBankProps } from '@pages/distributor/enums/funding-bank.enum';
import { ProductLineStatus } from '../../../core/enums/status.enum';
import { BlanketStatus, ProductLineProps } from '@pages/distributor/enums/product-lines.enum';
import { AddressProps } from '../../../core/enums/address.enum';
import { ContactProps } from '../../../core/enums/contact.enum';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { GuarantorProps, InsurerProps, SecurityType } from '@pages/distributor/enums/securities.enum';
import { BlanketsProps } from '@pages/distributor/enums/blankets.enum';
import { SourceLocationProps } from '@pages/distributor/enums/source-location-mapping.enum';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';

export class DistributorManagerConstantMock {
  static item = {
    [DistributorManagerProps.ID]: '1',
    [DistributorManagerProps.NAME]: 'Distributor Group 1',
    [DistributorManagerProps.GROUP_NAME]: 'Group 1',
    [DistributorManagerProps.LAST_MODIFIED_TIMESTAMP]: '2024/02/12',
    [DistributorManagerProps.STATUS]: 'SUSPENDED',
    [DistributorManagerProps.RISK_STATUS]: 'SUSPENDED',
    [DistributorManagerProps.TASK]: 'A'
  };
  static list = [
    { ...DistributorManagerConstantMock.item },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '222',
      [DistributorManagerProps.NAME]: 'Hector'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1',
      [DistributorManagerProps.NAME]: 'Distributor',
      [DistributorManagerProps.GROUP_NAME]: '-',
      [DistributorManagerProps.RISK_STATUS]: 'CLOSED'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '444'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '555'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '666'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '777'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '888'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '999'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1010'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1111'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1212'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1313'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1414'
    },
    {
      ...DistributorManagerConstantMock.item,
      [DistributorManagerProps.ID]: '1515'
    }
  ];

  static securities = {
    [GuarantorProps.ID]: '116161622',
    [GuarantorProps.NAME]: 'AUTOLEVELER TIBET',
    [GuarantorProps.STATUS]: 'XXX',
    [InsurerProps.MOODYS_RATING]: 'XXX',
    [InsurerProps.SPS_RATING]: 'XXX',
    [GuarantorProps.CURRENCY]: 'EUR',
    [GuarantorProps.AMOUNT]: 10450.0,
    [GuarantorProps.EFFECTIVE_DATE]: '2024-05-07T10:15:30Z',
    [GuarantorProps.EXPIRY_DATE]: '2024-05-07T10:15:30Z',
    [InsurerProps.COUNTRY_RISK_DEBTOR]: 'GERMANY',
    [InsurerProps.INSURANCE_COUNTRY_RYSK]: 'Low',
    [InsurerProps.STANDARD_LIMIT]: 1000000,
    [InsurerProps.COUNTRY_RISK_RATING]: 'D',
    [InsurerProps.EXCEPTION_LIMIT]: 1000000
  };

  static guarantor = {
    [GuarantorProps.ID]: '116161622',
    [GuarantorProps.NAME]: 'AUTOLEVELER TIBET',
    [GuarantorProps.STATUS]: 'XXX',
    [InsurerProps.MOODYS_RATING]: 'XXX',
    [InsurerProps.SPS_RATING]: 'XXX',
    [GuarantorProps.CURRENCY]: 'EUR',
    [GuarantorProps.AMOUNT]: 10450.0,
    [GuarantorProps.EFFECTIVE_DATE]: '2024-05-07T10:15:30Z',
    [GuarantorProps.EXPIRY_DATE]: '2024-05-07T10:15:30Z',
    [InsurerProps.COUNTRY_RISK_DEBTOR]: 'GERMANY',
    [InsurerProps.INSURANCE_COUNTRY_RYSK]: 'Low',
    [InsurerProps.STANDARD_LIMIT]: 1000000,
    [InsurerProps.COUNTRY_RISK_RATING]: 'D',
    [InsurerProps.EXCEPTION_LIMIT]: 1000000
  };

  static productLine = {
    [ProductLineProps.PL_NAME]: 'PL_NAME',
    [ProductLineProps.TYPE_CODE]: 'Type',
    [ProductLineProps.STATUS]: ProductLineStatus.ACTIVE,
    [ProductLineProps.SOURCE_LOCATION_CODE]: 'Source Location',
    [ProductLineProps.TERM]: 1,
    [ProductLineProps.SECURITY_CAPITAL]: 'Security',
    [ProductLineProps.PL_INVOICE_CURRENCY]: 'EUR',
    [ProductLineProps.BILLING_CURRENCY]: 'EUR',
    [ProductLineProps.ENCLAUSED]: true,
    [ProductLineProps.PL_BILLING_TYPE]: 'BILLING_TYPE',
    [ProductLineProps.FLAT_CHARGE]: 1,
    [ProductLineProps.FLAT_DISCOUNT]: 1,
    [ProductLineProps.INTEREST_CHARGE]: 1,
    [ProductLineProps.INTEREST_DISCOUNT]: 1,
    [ProductLineProps.RATE_ON_FLAT_CHARGE]: 1,
    [ProductLineProps.RATE_ON_INTEREST]: 1,
    [ProductLineProps.LATE_CHARGES_METHODS]: 'LATES_CHARGED_METHODS',
    [ProductLineProps.LATE_CHARGES_RATE]: 1,
    [ProductLineProps.GRACE_PERIOD]: 1,
    [ProductLineProps.INT_CHARGEABLE_DAYS]: 1,
    [ProductLineProps.PL_FUNDING]: 1,
    [ProductLineProps.PL_PAYEE_BANK]: 1,
    [ProductLineProps.PL_DOCUMENTATION_IN]: 1,
    [ProductLineProps.PL_PAYMENT_METHOD]: 1,
    [ProductLineProps.PRODUCT_LINES_CURRENCY]: 'EUR',
    [ProductLineProps.PL_ACTIVATION_DATE]: '2024/04/23'
  };
  static blanket = {
    [BlanketsProps.PRODUCT_LINE_ID]: 'Product Line',
    [BlanketsProps.CAPITAL]: 100,
    [BlanketsProps.STATUS]: BlanketStatus.ACTIVE
  };

  static productLines = [
    { ...this.productLine },
    { ...this.productLine },
    { ...this.productLine },
    { ...this.productLine }
  ];

  static productBlankets = {
    [BlanketsProps.PRODUCT_LINE_ID]: 'Lorem Ipsun Dolor Amet',
    [BlanketsProps.CAPITAL]: 'X.XXX.XXX,XX EUR',
    [BlanketsProps.STATUS]: BlanketStatus.ACTIVE
  };
  static blankets = [{ ...this.productBlankets }, { ...this.productBlankets }, { ...this.productBlankets }];
  static sourceLocation = {
    [SourceLocationProps.SOURCE_LOCATION_CODE]: '22261717',
    [SourceLocationProps.SOURCE_LOCATION_NAME]: 'Source',
    [SourceLocationProps.FTP_PROCESSING_CODE]: 'Euro'
  };

  static distributorCreditLimits = [
    {
      [DistributorGroupProps.DISTRIBUTOR_CODE]: '2277712',
      [DistributorGroupProps.DISTRIBUTOR_NAME]: 'AUTO LEVELER',
      [DistributorGroupProps.DISTRIBUTOR_CREDIT_LIMIT_AMOUNT]: 40000000,
      [DistributorGroupProps.DISTRIBUTOR_CURRENCY]: 'EUR'
    },
    {
      [DistributorGroupProps.DISTRIBUTOR_CODE]: '13553',
      [DistributorGroupProps.DISTRIBUTOR_NAME]: 'Sri Lanka Auto',
      [DistributorGroupProps.DISTRIBUTOR_CREDIT_LIMIT_AMOUNT]: 25000000,
      [DistributorGroupProps.DISTRIBUTOR_CURRENCY]: 'EUR'
    },
    {
      [DistributorGroupProps.DISTRIBUTOR_CODE]: '777383',
      [DistributorGroupProps.DISTRIBUTOR_NAME]: 'Auto Saruda, LDA',
      [DistributorGroupProps.DISTRIBUTOR_CREDIT_LIMIT_AMOUNT]: 25000000,
      [DistributorGroupProps.DISTRIBUTOR_CURRENCY]: 'EUR'
    }
  ];

  static distributorGroupById: any = {
    '1': {
      [DistributorGroupSection.GENERAL_INFORMATION]: {
        [DistributorGroupProps.NAME]: 'NAME',
        [DistributorGroupProps.COUNTRY]: {
          [CountryProps.CODE]: 'US',
          [CountryProps.NAME]: 'USA',
          [CountryProps.MEMBERSHIP]: 'US'
        },
        [DistributorGroupProps.CONTACT]: {
          [AddressProps.ADDRESS]: 'Calle WWTF',
          [AddressProps.TOWN]: 'Madrid',
          [AddressProps.POSTAL_CODE]: '28000',
          [ContactProps.NAME]: 'This is the Contact Name',
          [ContactProps.PHONE]: '555 555 555'
        },
        [DistributorGroupProps.DELETION_SUSPENSION_ORDER]: true,
        [DistributorGroupProps.LAST_MODIFIED_DATE]: '2024-05-08T11:13:50.422066Z',
        [DistributorGroupProps.CREATION_DATE]: '2024-05-08T11:13:50.422066Z',
        [DistributorGroupProps.VAT_NUMBER]: '123456'
      },
      [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]: {
        [DistributorGroupProps.BELONGS_TO_GROUP]: true,
        creditLimitAmount: 10,
        creditLimitStatus: 'A',
        currency: 'EUR',
        distributorGroup: undefined,
        effectiveDate: '2024-05-16T00:00:00.000Z',
        expiryDate: '2024-05-16T00:00:00.000Z',
        fundingBank: [{ fundingBank: 'value', fundingBankAmount: 10, fundingBankPercentage: 100 }],
        packApprovalDate: '2024-05-16T00:00:00.000Z',
        securities: [
          {
            countryRiskDebtor: 'EUR',
            effectiveDate: '2024-05-16T00:00:00.000Z',
            expiryDate: '2024-05-16T00:00:00.000Z',
            guarantorInsurerName: 'EUR',
            guarantorInsurerType: 'FDI',
            insuranceCountryRisk: 'USD',
            securityAmount: 10,
            securityCurrency: 'EUR',
            securityStatus: 'ACTIVE'
          }
        ]
      },
      [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]: this.distributorCreditLimits
    },
    default: {
      [DistributorGroupSection.GENERAL_INFORMATION]: {},
      [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]: {},
      [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]: []
    }
  };

  static distributorById: any = {
    '1': {
      [DistributorSection.GENERAL_INFORMATION]: {
        [DistributorProps.NAME]: 'NAME',
        [DistributorProps.ACCOUNT_STATUS]: DistributorStatus.CLOSED,
        [DistributorProps.OPERATION_STATUS]: 'CLOSED',
        [DistributorProps.RISK_STATUS]: 'ACTIVE',
        [DistributorProps.COUNTRY]: {
          [CountryProps.CODE]: 'US',
          [CountryProps.NAME]: 'USA',
          [CountryProps.MEMBERSHIP]: 'US'
        },
        [DistributorProps.ADDRESS]: {
          [AddressProps.ADDRESS]: 'Calle WWTF',
          [AddressProps.TOWN]: 'Madrid',
          [AddressProps.POSTAL_CODE]: '28000'
        },
        [DistributorProps.CONTACT]: {
          [ContactProps.NAME]: 'This is the Contact Name',
          [ContactProps.PHONE]: '555 555 555'
        },
        [DistributorProps.DELETION_SUSPENSION_ORDER]: true,
        [DistributorProps.LAST_MODIFIED_TIMESTAMP]: '2024-05-08T11:13:50.422066Z',
        [DistributorProps.CREATION_DATE]: '2024-05-08T11:13:50.422066Z',
        [DistributorProps.ID_NUMBER]: '123456'
      },
      [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: {
        [DistributorProps.BELONGS_TO_GROUP]: true,
        [DistributorProps.GROUP_CREDIT_LIMITS]: {
          [DistributorGroupProps.CODE]: 1,
          [DistributorGroupProps.NAME]: 'GROUP NAME',
          [DistributorGroupProps.CREDIT_LIMIT_AMOUNT]: 1000,
          [DistributorGroupProps.BANK_GUARANTEE_AMOUNT]: 5000,
          [DistributorGroupProps.BANK_GUARANTEE_CURRENCY]: 'EUR',
          [DistributorGroupProps.FDI_AMOUNT]: 6000,
          [DistributorGroupProps.FDI_CURRENCY]: 'EUR'
        },
        credit: {
          [CreditProps.CURRENCY]: 'EUR',
          status: 'ACTIVE',
          capital: 5000000.0,
          availableCapital: 5000000.0,
          totalGroupCapital: 5000000.0,
          bankGuaranteedCapital: 5000000.0,
          standbyLetter: 3000000.0,
          fdi: 3000000.0,
          effectiveDate: '2024-05-07T10:15:30Z',
          expiryDate: '2024-05-14T20:53:12Z',
          packApprovalDate: '2024-05-07T17:54:36.010Z'
        },
        [DistributorProps.FUNDING_BANK]: [
          {
            [FundingBankProps.NAME]: 'Bank Name 1',
            [FundingBankProps.FUNDING_BANK_AMOUNT]: 5000000.0,
            [FundingBankProps.FUNDING_BANK_PERCENTAGE]: 100.0
          },
          {
            [FundingBankProps.NAME]: 'Bank Name 2',
            [FundingBankProps.FUNDING_BANK_AMOUNT]: 5000000.0,
            [FundingBankProps.FUNDING_BANK_PERCENTAGE]: 100.0
          }
        ],
        [DistributorProps.SECURITIES]: {
          [SecurityType.GUARANTORS]: [DistributorManagerConstantMock.guarantor],
          [SecurityType.INSURERS]: []
        }
      },
      [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: {
        [DistributorProps.PRODUCT_LINES]: [{ ...DistributorManagerConstantMock.productLine, [ProductLineProps.PL_ID]: 1 }],
        [DistributorProps.BLANKETS]: [{ ...DistributorManagerConstantMock.blanket, [BlanketsProps.ID]: 1 }]
      },
      [DistributorSection.SOURCE_LOCATION_MAPPING]: [this.sourceLocation],
      [DistributorSection.OBSERVATIONS]: 'Observations'
    },
    default: {
      [DistributorSection.GENERAL_INFORMATION]: {},
      [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: {},
      [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: {},
      [DistributorSection.SOURCE_LOCATION_MAPPING]: [],
      [DistributorSection.OBSERVATIONS]: {}
    }
  };
}
