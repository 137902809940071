import { ProductLineStatus, ProductLineType, StatusConfigProps } from '../../../core/enums/status.enum';
import { ProductLineProps } from '@pages/distributor/enums/product-lines.enum';
import { ColorType } from '../../../shared/enums/color.enum';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { AlignRow, ColumnSize, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { BlanketsProps } from '@pages/distributor/enums/blankets.enum';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';

export class ProductLinesConstant {
  static titleKeyPrefix = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.PRODUCT_LINES_AND_BLANKETS';

  static productLineStatusConfig: any = {
    [ProductLineStatus.ACTIVE]: {
      [StatusConfigProps.KEY]: 'STATUS.PRODUCT_LINE_STATUS.ACTIVE',
      [StatusConfigProps.COLOR]: ColorType.SUCCESS
    },
    [ProductLineStatus.CLOSED]: {
      [StatusConfigProps.KEY]: 'STATUS.PRODUCT_LINE_STATUS.CLOSED',
      [StatusConfigProps.COLOR]: ColorType.DANGER
    },
    [ProductLineStatus.PENDING]: {
      [StatusConfigProps.KEY]: 'STATUS.PRODUCT_LINE_STATUS.PENDING',
      [StatusConfigProps.COLOR]: ColorType.PURPLE
    }
  };
   static productLinesStatus: any = {
    [ProductLineStatus.ACTIVE]: 'A'
   }

  static statusPropertyByFormType = {
    [DistributorProps.PRODUCT_LINES]: ProductLineProps.STATUS,
    [DistributorProps.BLANKETS]: BlanketsProps.STATUS
  };

  static tableListProductLinesConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: ProductLineProps.PL_NAME,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.PRODUCT_LINE_NAME`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.BILLING_TYPE_CODE,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.TYPE`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.EFFECTIVE_TIMESTAMP,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.ACTIVATION_DATE`,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }],
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.EXPIRY_TIMESTAMP,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.EXPIRY_DATE`,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }],
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.DISTRIBUTOR_SECURITY_ID,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.SECURITY`,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatCurrencyPipe }]
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.TERM_DAYS,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.TERM`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.SRC_LOCATION_INV_CURRENCY_CODE,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.CURRENCY`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: ProductLineProps.STATUS,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.STATUS`,
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
      [TableFieldProps.STATUS_MAP]: ProductLinesConstant.productLineStatusConfig,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.WIDTH]: ColumnSize.SMALL
    }
  ];

  static tableListProductLinesConfigWithView = [
    ...ProductLinesConstant.tableListProductLinesConfig,
    {
      [TableFieldProps.FIELD]: DistributorProps.PARAMETERS,
      [TableFieldProps.HEADER]: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.VIEW`,
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static tableListProductLinesConfigWithActions: TableField[] = [
    ...ProductLinesConstant.tableListProductLinesConfig,
    {
      [TableFieldProps.FIELD]: TableFieldProps.MENU_OPTION_LIST,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.OPTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.MENU_ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static productTypeStatusMapper = [
    { titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.TYPE`, path: [ProductLineProps.TYPE_CODE] },
    { titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.STATUS`, path: [ProductLineProps.STATUS] }
  ];

  static productSourceLocationTermMapper = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.SOURCE_LOCATION`,
      path: [ProductLineProps.SOURCE_LOCATION_CODE]
    },
    { titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.TERM`, path: [ProductLineProps.TERM] }
  ];

  static productInvoiceBillingCurrencyMapper = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.INVOICE_CURRENCY`,
      path: [ProductLineProps.PL_INVOICE_CURRENCY]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.BILLING_CURRENCY`,
      path: [ProductLineProps.BILLING_CURRENCY]
    }
  ];

  static encluasedBillingTypeMapper = [
    { titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.ENCLAUSED`, path: [ProductLineProps.ENCLAUSED] },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.BILLING_TYPE`,
      path: [ProductLineProps.BILLING_TYPE_CODE]
    }
  ];

  static productMapperConfig = {
    titleKey: `${ProductLinesConstant.titleKeyPrefix}.TITLES.PRODUCT`,
    children: [
      ...ProductLinesConstant.productTypeStatusMapper,
      ...ProductLinesConstant.productSourceLocationTermMapper,
      ...ProductLinesConstant.productInvoiceBillingCurrencyMapper,
      ...ProductLinesConstant.encluasedBillingTypeMapper
    ]
  };

  static ratesFlatChargeDiscountMapper = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.FLAT_CHARGE`,
      path: [ProductLineProps.FLAT_CHARGE_PERCENTAGE]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.FLAT_DISCOUNT`,
      path: [ProductLineProps.FLAT_DISCOUNT_PERCENTAGE]
    }
  ];

  static interestChargeDiscountMapper = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.INTEREST_CHARGE`,
      path: [ProductLineProps.INTEREST_CHARGE_PERCENTAGE]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.INTEREST_DISCOUNT`,
      path: [ProductLineProps.INTEREST_DISCOUNT_PERCENTAGE]
    }
  ];

  static ratesMapperConfig = {
    titleKey: `${ProductLinesConstant.titleKeyPrefix}.TITLES.RATES`,

    children: [
      ...ProductLinesConstant.ratesFlatChargeDiscountMapper,
      ...ProductLinesConstant.interestChargeDiscountMapper
    ]
  };

  static rateOnFlatChargeInterest = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.RATE_ON_FLAT_CHARGE`,
      path: [ProductLineProps.RATE_ON_FLAT_CHARGE]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.RATE_ON_INTEREST`,
      path: [ProductLineProps.RATE_ON_INTEREST]
    }
  ];

  static wLateCharges = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.LATE_CHARGES_METHODS`,
      path: [ProductLineProps.LATE_CHARGES_METHODS]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.LATE_CHARGES_RATE`,
      path: [ProductLineProps.LATE_CHARGES_RATE]
    }
  ];

  static wGraPeriodIntChargeable = [
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.GRACE_PERIOD`,
      path: [ProductLineProps.GRACE_PERIOD]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.INT_CHARGEABLE_DAYS`,
      path: [ProductLineProps.INT_CHARGEABLE_DAYS]
    }
  ];

  static whitholdingTaxMapperConfig = {
    titleKey: `${ProductLinesConstant.titleKeyPrefix}.TITLES.WHITHOLDING_TAX`,

    children: [
      ...ProductLinesConstant.rateOnFlatChargeInterest,
      ...ProductLinesConstant.wLateCharges,
      ...ProductLinesConstant.wGraPeriodIntChargeable
    ]
  };

  static fundsSecuritiesChildrenMapper = [
    { 
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.FUNDING`,
      path: [ProductLineProps.PL_FUNDING] 
    },
    { 
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.INSURER`,
      path: [ProductLineProps.PL_INSURER] 
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.SECURITY`,
      path: [ProductLineProps.SECURITY_CAPITAL]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.PAYEE_BANK`,
      path: [ProductLineProps.PAYEE_BANK_CODE]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.DOCUMENTATION_IN`,
      path: [ProductLineProps.PL_DOCUMENTATION_IN]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.FIELDS.PAYMENT_METHOD`,
      path: [ProductLineProps.PAYMENT_METHOD_CODE]
    }
  ];

  static fundsSecuritiesMapperConfig = {
    titleKey: `${ProductLinesConstant.titleKeyPrefix}.TITLES.FUNDS_AND_SECURITIES`,

    children: [...ProductLinesConstant.fundsSecuritiesChildrenMapper]
  };

  static productLinesConfigMapper = {
    titleKey: `${ProductLinesConstant.titleKeyPrefix}.TITLES.PRODUCT_LINES`,
    childrenKey: [DistributorProps.PRODUCT_LINES],
    isArray: true,
    children: [
      ProductLinesConstant.productMapperConfig,
      ProductLinesConstant.ratesMapperConfig,
      ProductLinesConstant.whitholdingTaxMapperConfig,
      ProductLinesConstant.fundsSecuritiesMapperConfig
    ]
  };
}
