import { Injectable } from '@angular/core';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { FormConfig, FormItem } from '@utils/models/Form';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductLineStatus } from '../../../core/enums/status.enum';
import { NextInputValidators } from '../../../shared/validators/input.validator';
import { GenericObject } from '@utils/models/Types';
import { pbFormType } from '@pages/distributor/types/product-line-blankets.type';
import { ProductLineProps } from '@pages/distributor/enums/product-lines.enum';
import { BlanketsProps } from '@pages/distributor/enums/blankets.enum';

@Injectable({
  providedIn: 'root'
})
export class ProductLinesBlanketsAdminService {
  constructor(private _fb: FormBuilder) {}

  buildProductLinesFormArray(productLines: any = [], isEdit: boolean = false) {
    const groups = productLines.map((productLine: any) => this.buildProductLineFormGroup(productLine));
    return this._fb.array(groups, []);
  }

  buildProductLineFormGroup(productLineValues: any = {}) {
    const { props, config } = this._buildProductLineConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, productLineValues, config);
    return this._fb.group(formGroupState);
  }

  handleValidationsBasedOnProductLineStatus(formGroup: FormGroup, status: ProductLineStatus) {
    switch (status) {
      case ProductLineStatus.ACTIVE:
        this._handleEnableAndRequiredControl(formGroup.get(ProductLineProps.EFFECTIVE_TIMESTAMP)!, true);
        this._handleEnableAndRequiredControl(formGroup.get(ProductLineProps.EXPIRY_TIMESTAMP)!, false);

        break;
      case ProductLineStatus.PENDING:
        this._handleEnableAndRequiredControl(formGroup.get(ProductLineProps.EFFECTIVE_TIMESTAMP)!, false);
        this._handleEnableAndRequiredControl(formGroup.get(ProductLineProps.EXPIRY_TIMESTAMP)!, false);
        break;
      case ProductLineStatus.CLOSED:
        this._handleEnableAndRequiredControl(formGroup.get(ProductLineProps.EFFECTIVE_TIMESTAMP)!, true);
        this._handleEnableAndRequiredControl(formGroup.get(ProductLineProps.EXPIRY_TIMESTAMP)!, true, false);
        break;
    }
  }

  buildBlanketsFormArray(blankets: any = [], isEdit: boolean) {
    const groups = blankets.map((blanket: any) => this.buildBlanketFormGroup(blanket, isEdit));
    return this._fb.array(groups, []);
  }

  buildBlanketFormGroup(blanket?: any, isEdit: boolean = false) {
    const { props, config } = this._buildBlanketConfigValues(isEdit);

    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, blanket, config);
    return this._fb.group(formGroupState);
  }

  formGroupByType(type: pbFormType, formValues: GenericObject): FormGroup {
    const formFunctions: GenericObject = {
      [DistributorProps.BLANKETS]: this.buildBlanketFormGroup(formValues),
      [DistributorProps.PRODUCT_LINES]: this.buildProductLineFormGroup(formValues)
    };
    return formFunctions[type];
  }

  private _handleEnableAndRequiredControl(control: FormItem, isEnabled: boolean, isRequired = isEnabled) {
    NextFormHelper.updateControlEnableStatus(control, isEnabled, { resetValue: !isEnabled });
    NextFormHelper.updateControlRequiredValidator(control, isRequired);
  }

  private _buildProductLineConfigValues() {
    const props = [
      ProductLineProps.PL_ID,
      ProductLineProps.DISTRIBUTOR_ID,
      ProductLineProps.PL_NAME,
      ProductLineProps.PT_CODE,
      ProductLineProps.SL_CODE,
      ProductLineProps.PL_STATUS_CODE,
      ProductLineProps.TERM_DAYS,
      ProductLineProps.DISTRIBUTOR_BILLING_CURRENCY_CODE,
      ProductLineProps.SRC_LOCATION_INV_CURRENCY_CODE,
      ProductLineProps.PAYEE_BANK_CODE,
      ProductLineProps.FUNDING_BANK_CODE,
      ProductLineProps.DISTRIBUTOR_INSURER_ID,
      ProductLineProps.DISTRIBUTOR_SECURITY_ID,
      ProductLineProps.ENCLAUSED_ID,
      ProductLineProps.BYLLING_TYPE_CODE,
      ProductLineProps.PAYMENT_METHOD_CODE,
      ProductLineProps.EFFECTIVE_TIMESTAMP,
      ProductLineProps.EXPIRY_TIMESTAMP,
      ProductLineProps.FLAT_CHARGE_PERCENTAGE,
      ProductLineProps.FLAT_DISCOUNT_PERCENTAGE,
      ProductLineProps.INTEREST_CHARGE_PERCENTAGE,
      ProductLineProps.INTEREST_DISCOUNT_PERCENTAGE,
      ProductLineProps.WH_FLAT_CHARGE_PERCENTAGE,
      ProductLineProps.WH_INT_CHARGE_PERCENTAGE,
      ProductLineProps.WH_LATE_CHARGE_PMT_METHOD_CODE,
      ProductLineProps.WH_LATE_CHARGE_PERCENTAGE,
      ProductLineProps.WH_GRACE_PERDAYS,
      ProductLineProps.WH_INT_CHARGEABLE_DAYS,
      ProductLineProps.CREATED_TIMESTAMP,
      ProductLineProps.CREATED_USER_ID_CODE,
      ProductLineProps.LAST_UPDATE_TIMESTAMP,
      ProductLineProps.LAST_UPDATE_USER_ID_CODE,
      ProductLineProps.BLANKET_STATUS_CODE,
      ProductLineProps.BLANKET_AMOUNT,
      ProductLineProps.DOC_LOCATION_TX
    ];

    const config: FormConfig = {
    //   [ProductLineProps.PL_NAME]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.TYPE_CODE]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.STATUS]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.SOURCE_LOCATION_CODE]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.TERM]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.PL_INVOICE_CURRENCY]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.BILLING_CURRENCY]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.ENCLAUSED]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.PL_BILLING_TYPE]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.FLAT_CHARGE]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.FLAT_DISCOUNT]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.INTEREST_CHARGE]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.INTEREST_DISCOUNT]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.RATE_ON_FLAT_CHARGE]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.RATE_ON_INTEREST]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.LATE_CHARGES_METHODS]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.LATE_CHARGES_RATE]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.GRACE_PERIOD]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.INT_CHARGEABLE_DAYS]: {
    //     isRequired: true,
    //     validators: [NextInputValidators.greaterThan(0)]
    //   },
    //   [ProductLineProps.PL_FUNDING]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.PL_PAYEE_BANK]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.PL_DOCUMENTATION_IN]: {
    //     isRequired: true
    //   },
    //   [ProductLineProps.PL_PAYMENT_METHOD]: {
    //     isRequired: true
    //   }
    };

    return { props, config };
  }

  private _buildBlanketConfigValues(isEdit: boolean = false) {
    const props = [BlanketsProps.PRODUCT_LINE_ID, BlanketsProps.CAPITAL, BlanketsProps.STATUS];

    const config: FormConfig = {
      [BlanketsProps.PRODUCT_LINE_ID]: {
        isRequired: true,
        isDisabled: !isEdit
      },
      [BlanketsProps.CAPITAL]: {
        isRequired: true,
        isDisabled: !isEdit
      },
      [BlanketsProps.STATUS]: {
        isRequired: true,
        isDisabled: !isEdit
      }
    };

    return { props, config };
  }
}
